import { PopoverTrigger } from "@chakra-ui/react"
import { SmileyIcon, SmileyIconV2 } from "@mybridge/icons"
import { Emoji } from "@mybridge/ui/emoji"
import { IconButton } from "@mybridge/ui/icon-button"
import { Popover, PopoverContent } from "@mybridge/ui/popover"
import { Tooltip } from "@mybridge/ui/tooltip"
import { useContext , useState } from "react"
import { PostFormContext } from "../context"
import { Box } from "@mybridge/ui/layout"
import { useDisclosure } from "@mybridge/ui/hooks"

export const EmojiPicker = ({ }) => {
    const { setText, text, textInputRef, setEmojis } = useContext(PostFormContext)
    const emojiDisc = useDisclosure()
    const insertAtPos = (value) => {
        setEmojis(prevState => [...prevState, value])
        // const { current: ref } = textInputRef ?? {};
        // if (ref) {
        //     let startPos = ref.selectionStart;
        //     let endPos = ref.selectionEnd;
        //     const text_ = ref.value.substring(0, startPos) + value.native + ref.value.substring(endPos, ref.value.length)
            
        //     setText(text_);
        // }
    };
    const [iconColor, setIconColor] = useState('');

    const handleMouseEnter = () => {
        setIconColor('#ffffff');
    };

    const handleMouseLeave = () => {
      setIconColor('');
    };

    return (
        <>
            <Popover {...emojiDisc}>
                <PopoverTrigger>
                    <Box>
                        <Tooltip variant="round" label="Add Emoji">
                            <IconButton 
                                borderRadius="30px"
                                padding="6px"
                                _hover={{ background: '#3D5A80' }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                 icon={<SmileyIconV2 color={iconColor} />} />
                        </Tooltip>
                    </Box>
                </PopoverTrigger>
                <PopoverContent>
                    {emojiDisc.isOpen ? <Emoji onEmojiSelect={insertAtPos} /> : ""}
                </PopoverContent>
            </Popover>
        </>
    )
}