/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { HStack, Stack } from '@mybridge/ui/layout';
import { useContext, useEffect } from 'react';
import { CommentControlMenu } from '../comment-control-menu';
import { useSelector } from 'react-redux';
import { PostFormContext } from '../context';
import { EmojiPicker } from '../emoji-picker';
import { GifPicker } from '../gif-picker';
import { MediaPicker } from '../media-picker';
import { PostFormMorePicker } from '../more-picker';
import { Reactions } from '../reactions';
import { isDesktop, isMobile } from 'react-device-detect';
import { Divider } from '@mybridge/ui';

export const PostFormFooter = ({ companyDetails ,pagePost,visibility}) => {
  const {
    mode,
    text,
    textMaxLength,
    submitPost,
    isLoading,
    hasContent,
    companyPosting,
  } = useContext(PostFormContext);
  const inputEnabled = hasContent && text?.length <= textMaxLength;
  const { conpanyActivities } = useSelector((state) => state?.companyPage);
  useEffect(() => {
    if (companyDetails) {
      companyPosting(companyDetails);
    }
  }, [companyDetails]);

  return (
    <Stack>
      {isMobile && (
        <>
          <HStack justifyContent="flex-end" mb={3}>
            <Reactions />
            <CommentControlMenu visibility={visibility} />
            
          </HStack>
          <Divider />
          </>
        )}
      <HStack>
        <HStack flex={1}>
          <MediaPicker />
          <GifPicker />
          <EmojiPicker />
          <PostFormMorePicker />
          {/* <IconButton icon={<EventCalendarIcon />} />
            <IconButton icon={<ArticleIcon />} /> */}
        </HStack>
        {isDesktop && (
          <HStack>
            <Reactions />
            <CommentControlMenu visibility={visibility} />
          </HStack>
        )}
      </HStack>
      {/* {!conpanyActivities?.results?.length && (
      <HStack alignItems="start" flex={1} justifyContent="flex-end">
        <Button
          isDisabled={!inputEnabled}
          onClick={submitPost}
          variant="slimPrimary"
          isLoading={isLoading}
        >
          {mode === 'create' ? 'Post' : 'Update'}
        </Button>
      </HStack>
      )} */}
    </Stack>
  );
};
