/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  CloseButton,
  HStack,
  IconButton,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@mybridge/ui';
import { forwardRef, useContext, useMemo, useState } from 'react';
import {
  AttendingIconMap,
  CelebratingIconMap,
  FeelingsIconMap,
} from 'v4/lib/constants';
import { PostFormContext } from '../context';
import { toPascalCase } from 'v4/lib/commons';

export const PostFormFeelingsPicker = forwardRef(({ ...props }, ref) => {
  const {
    setOpenMoreOptions,
    setMoreOptionsActiveTab,
    feeling,
    setFeeling,
    feelingsAndActivities,
    moreOptionsDisc,
  } = useContext(PostFormContext);
  const tabs = useMemo(
    () => Object?.keys(feelingsAndActivities),
    [feelingsAndActivities]
  );
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onSelect = (val) => {
    console.log(val);
    if(feeling && feeling.name === val.name){
      setFeeling(null);
    }else{
      setFeeling(val);
    }
    moreOptionsDisc?.onClose?.();
  };

  return (
    <>
      <HStack mb={4}>
        <IconButton
          onClick={(e) => setMoreOptionsActiveTab(null)}
          variant="ghost"
        >
          <ChevronLeftIcon />
        </IconButton>
        <HStack flex={1}>
          <Text fontWeight="bold" color="brandPrimary.500">
            {activeTab === 'feeling'
              ? 'How are you feeling?'
              : 'What are you doing?'}
          </Text>
        </HStack>
        <CloseButton onClick={(e) => {
          setOpenMoreOptions(false);
          setMoreOptionsActiveTab(null);
        }} />
      </HStack>
      <Tabs index={tabs?.indexOf(activeTab)}>
        <TabList>
          {tabs?.map?.((t, tIndex) => (
            t !== 'More' &&
            <Tab onClick={(e) => setActiveTab(t)} key={tIndex}>
              {toPascalCase(t)}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.values(feelingsAndActivities)?.map?.((fa, faIndex) => (
            <TabPanel px={0} key={faIndex}>
              {fa?.length ? (
                <HStack
                  flexDir={faIndex < 1 ? 'row' : 'column'}
                  spacing={0}
                  alignItems="flex-start"
                  flexWrap="wrap"
                > 
                  {fa?.map?.((f, fIndex) => (
                    <HStack
                      cursor="pointer"
                      _hover={{ bg: 'gray.100' }}
                      p={2}
                      borderRadius="5"
                      key={fIndex}
                      w={faIndex<1?"33.33%":"full"}
                      onClick={(e) => onSelect(f)}
                      bg={feeling?.name===f.name && 'gray.200' }
                    >
                      <Stack flexShrink={0}>
                        <Image src={f.file} w="6" h="6" />
                      </Stack>
                      <Text wordBreak="none">{f?.name}</Text>
                    </HStack>
                  ))}
                </HStack>
              ) : (
                ''
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
});
