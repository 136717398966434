/* eslint-disable @nx/enforce-module-boundaries */
import { FormControl } from '@mybridge/ui/form-control';
import { useOutsideClick } from '@mybridge/ui/hooks';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import { Textarea } from '@mybridge/ui/text-area';
import { SlideFade } from '@mybridge/ui/transitions';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { PostFormContext } from '../context';

import { AttachedMediaContainer } from '../attached-media-display';

export const PostFormBody = ({ showMedia,editingIndex, ...props }) => {
  const {
    setText,
    text,
    textInputRef,
    textMaxLength = 300,
    expanded,
    setExpanded,
    formContainerRef,
    hasContent,
    mode,
    multiPost,
  } = useContext(PostFormContext) ?? {};
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  useOutsideClick({
    ref: formContainerRef,
    handler: () => handleOutsideClick(),
  });
  const handleTextChange = (e) => {
    setText?.(e?.target?.value);
    autoGrow(e);
  };

  const handleTextClick = (e) => {
    setExpanded(true);
  };

  const handleOutsideClick = () => {
    if (!hasContent) {
      setExpanded?.(true);
    }
  };

  const autoGrow = (e) => {
    const ele = e?.target;
    if (ele) {
      ele.style.height = '5px';
      ele.style.height = ele?.scrollHeight + 'px';
    }
  };


  return (
    <Stack {...props} zIndex="1">
      {/* <FormControl isInvalid={text?.length > textMaxLength}>
        <Textarea
          outline="none"
          _focusVisible={{ outline: 'none' }}
          ref={textInputRef}
          h={12}
          minH="12"
          maxH="64"
          fontSize="lg"
          value={text}
          autoFocus
          placeholder={`What's on your mind ${
            props?.companyDetails ? props?.companyDetails?.name : userProfileInfo?.first_name
          }?`}
          onClick={handleTextClick}
          onChange={handleTextChange}
          {...(expanded
            ? {
                bg: 'transparent',
                border: 'none',
              }
            : {})}
        />
      </FormControl> */}
      {text?.length > 0 ? (
        <HStack
          justifyContent="flex-end"
          color={text?.length > textMaxLength ? 'red' : ''}
        >
          {/* <SlideFade direction="right" in={text?.length > textMaxLength}>
            Should be less than {textMaxLength} characters!
          </SlideFade> */}
          {mode === "edit" && multiPost?.length ? <Text>
            {text?.length > textMaxLength ?(
              <span style={{color:'#E41C19'}}>{multiPost?.[editingIndex]?.content?.length}</span>
              ): (
              <span style={{ color: '#037F29' }}>{multiPost?.[editingIndex]?.content?.length ?? 0}</span>
              )}
            /{textMaxLength}
          </Text> : <Text>
            {text?.length > textMaxLength ?(
              <span style={{color:'#E41C19'}}>{text?.length }</span>
              ): (
              <span style={{ color: '#037F29' }}>{text?.length ?? 0}</span>
              )}
            /{textMaxLength}
          </Text>}
        </HStack>
      ) : (
        ''
      )}
     
    </Stack>
  );
};
