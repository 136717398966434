/* eslint-disable @nx/enforce-module-boundaries */
import { CheckMark1, ChevronDown, NewGlobe } from '@mybridge/icons';
import { HStack } from '@mybridge/ui/layout';
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@mybridge/ui/menu';

import { Text } from '@mybridge/ui/text';
import { useContext, useState } from 'react';
import { PostVisibilityOptions } from '../../../snippets/dashboard/constants';
import { PostFormContext } from '../context';
import { Portal } from '@mybridge/ui';

import { useEffect } from 'react';

export const CommentControlMenu = ({ visibility }) => {
  const {
    replyControl = PostVisibilityOptions?.[0]?.value,
    setReplyControl,
    isControlMenuOpen,
    setIsControlMenuOpen,
    setIsReactionMenuOpen
  } = useContext(PostFormContext);

  // Filter visibility options based on the passed visibility prop
  const filteredVisibilityOptions = PostVisibilityOptions.filter((option) => {
    if (visibility === 0) return true; // Everyone can see all options
    if (visibility === 2) return option.value !== 0; // myCircle & followers can see myCircle & followers and myCircle
    if (visibility === 1) return option.value === 1; // myCircle can only see myCircle
  });

  // State management
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Who can reply?');

  useEffect(() => {
    // Set the default reply control and selected option when the component mounts
    if (visibility === 2) {
      // Automatically set the reply control to 2 if visibility is myCircle & followers
      setReplyControl(2);
    } else if (filteredVisibilityOptions.length > 0) {
      setReplyControl(filteredVisibilityOptions[0]?.value);
    }
  }, [visibility, filteredVisibilityOptions, setReplyControl]);

  const replyControl_ = filteredVisibilityOptions?.find?.(rc => rc?.value === replyControl);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      {isControlMenuOpen ? (
        <HStack>
          {replyControl_?.icon}
          <Text
            onClick={() => {
              setMenuOpen(true);
              setIsControlMenuOpen(!isControlMenuOpen);
              setIsReactionMenuOpen(isControlMenuOpen);
            }}
          >
            {selectedOption}
          </Text>
        </HStack>
      ) : (
        <Menu zIndex="1" onClose={handleMenuClose} isOpen={menuOpen}>
          <MenuButton
            variant="transparent"
            color="#5B5B5B"
            fontSize="sm"
            width="202px"
            height="40px"
            padding="10px 14px"
            borderRadius="20px"
            boxShadow="0px 2px 6px 0px #00000033"
            onClick={() => {
              setMenuOpen(!menuOpen);
              setIsControlMenuOpen(isControlMenuOpen);
              setIsReactionMenuOpen(!isControlMenuOpen);
            }}
          >
            <HStack>
              {replyControl_?.icon}
              <Text color="gray.700">
                <Text color="gray.600">{selectedOption}</Text>
              </Text>
            </HStack>
          </MenuButton>

          <Portal>
            <MenuList
              zIndex={10000}
              shadow="base"
              position="absolute"
              top="-50px"
              minWidth="auto"
              width="202px"
              borderRadius="20px 20px 10px 10px"
            >
              <MenuGroup>
                <HStack
                  padding="6px 12px"
                  borderBottom="1px solid #E4E6EB"
                  onClick={() => {
                    setSelectedOption('Who can reply?');
                    handleMenuClose();
                  }}
                >
                  {replyControl_?.icon}
                  <Text color="gray.700">Who can reply?</Text>
                </HStack>
                {filteredVisibilityOptions.map((vo, voind) => (
                  <MenuItem
                    key={voind}
                    onClick={() => {
                      setReplyControl(vo?.value);
                      setSelectedOption(vo?.title);
                    }}
                    icon={vo.icon}
                  >
                    <HStack>
                      <Text flex={1}>{vo.title}</Text>
                      {vo?.value === replyControl ? <CheckMark1 /> : ''}
                    </HStack>
                  </MenuItem>
                ))}
              </MenuGroup>
            </MenuList>
          </Portal>
        </Menu>
      )}
    </>
  );
};
